export const invoiceLogos = ['inv_logo']
export const hasSalesProgramSplit = false
export const LABEL_EVENT = "Product family"
export const HORUS_CK = ""
export const LABEL_CRM = "Enc"
export const LABEL_QNOTE = "Note"
export const DEFAULT_VALUE_LONG_NOTE = ``
export const SUCC_URL = ""
export const STRIPE_KEY = ""
export const REGISTRATION_FORM_CLASS = ""
export const TERMS_CAPTION = 'I Accept the general Terms of Sales'